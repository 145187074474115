import React, { useEffect } from 'react';
import "../css/guideline.css";

// markup
const GuidelinePage = () => {

    useEffect(() => {

    }, []);
    return (
        <div className="guideline_wrapper" id="guideline">
            <h1>二次創作ガイドライン</h1>
            <hr></hr>
            <h2>はじめに</h2>
            <p>株式会社ライズアップ（以下当社）は、当社コンテンツ『PROJECT NEBULA』を用いた二次創作を、より多くの皆様に安心してお楽しみいただけるよう、本ガイドラインを制定いたします。</p>
            <p>本ガイドラインの内容に沿った当社コンテンツの二次創作については、個別の申請やご連絡は必要ありません。</p>
            <p>本ガイドラインとマナーを守って、ご自由に二次創作をお楽しみいただければ幸いです。</p>
            <h2>対象者</h2>
            <p>個人の皆様およびサークル等法人格を持たない団体の皆様。</p>
            <p> ※法人の方は個別にお問い合わせください。</p>
            <h2>二次創作の許諾範囲</h2>
            <p>二次創作とは、当社が提供する一次創作コンテンツを元に、対象者の皆様が創意工夫をもって創作されたものであると、当社は考えます。当社コンテンツの複製・転載はお控えください。</p>
            <p>また、皆様の二次創作作品については、当社所属タレントがSNSや動画サムネイル等に使用させていただく場合がございます。予めご了承ください。</p>
            <h2>規定</h2>
            <p>二次創作にあたり、以下の事項を遵守していただくようお願いいたします。</p>
            <ul>
                <li>当社コンテンツの二次創作作品を第三者に公開した時点で、本ガイドラインに同意したものとさせていただきます。</li>
                <li>同人活動や個人の趣味の範囲であれば、二次創作作品を販売・収益化をすることができます。
                    但し、原価回収の範疇を超えるものは個人の範囲とは認められません。</li>
            </ul>
            <p>以下の事項に該当する場合は、二次創作作品の公開を禁止します。</p>
            <ul>
                <li>公序良俗に反する、または反社会的な表現を含むもの</li>
                <li>当社の公式コンテンツと誤認される可能性のあるもの</li>
                <li>当社コンテンツのイメージを著しく損なうもの</li>
                <li>特定の思想・信条または宗教的、政治的な内容を含むもの</li>
                <li>第三者の名誉を傷つける、または権利を侵害する恐れがあるもの</li>
                <li>その他、当社が不適切と判断するもの</li>
            </ul>
            <h2>切り抜き動画に関する規定</h2>
            <p>当社コンテンツの提供する動画を使用した切り抜き動画を制作する場合は、上記の規定に加えて、以下の事項を遵守していただくようお願いいたします。</p>
            <ul>
                <li>必ず出典元のリンクを概要欄等に記載してください。</li>
                <li>メンバーシップなどの有料コンテンツの切り抜きはお控えください。</li>
                <li>切り抜き動画については、本ガイドラインを遵守していただければ、Youtubeにおける収益化をご利用いただけます。</li>
                <li>歌枠の切り抜きについては、第三者の権利を侵害する恐れがあります。配信で使用している音源等の権利を今一度ご確認ください。</li>
            </ul>
            <br></br>
            <p>2022年11月1日改訂</p>
        </div>
    )
}

export default GuidelinePage
